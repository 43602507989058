<template>
  <div class="form-group">
    <div class="d-flex">
      <label v-if="label" class="form-label">{{ label }}</label>
      <span
        class="ml-auto badge badge-sm rounded-bottom-0"
        :class="{
          'bg-primary': lgt < (max / 3) * 2,
          'bg-warning': lgt >= (max / 3) * 2
        }"
        v-if="max"
        >{{ lgt }}/{{ max }}</span
      >
    </div>

    <div class="input-wrap">
      <textarea
        ref="textarea"
        class="form-input"
        aria-label="textarea"
        :class="style"
        :rows="rows || 10"
        :placeholder="placeholder"
        v-model="_value"
        :maxlength="max"
        :style="{ height: '130px' }"
        @click="$emit('click', $event)"
        @keyup="$emit('keyup', $event)"
        @input="onKeyDown"
        @keypress="$emit('keypress', $event)"
        @change="$emit('change', $event)"
        @blur="$emit('blur', $event)"
        @focus="$emit('focus', $event)"
      ></textarea>
      <span
        class="emoji-trigger"
        :class="{ triggered: showEmojiPicker }"
        @mousedown.prevent="toggleEmojiPicker"
        v-on:blur="toggleEmojiPicker"
      >
        <IconBase :width="40" viewbox="0 0 24 24">
          <Smile />
        </IconBase>
      </span>
      <picker
        class="emoji-panel"
        title="Pick your emoji..."
        emoji="point_up"
        v-show="showEmojiPicker"
        @select="addEmoji"
      />
    </div>
    <perfect-scrollbar
      class="scroll-wrap d-flex flex-column"
      v-if="showSearchPanel"
    >
      <nav class="nav flex-column border" v-if="users">
        <span
          class="btn h-100 py-1 border-bottom option"
          :class="{ selected: selected === i }"
          @click="setUser(option.username)"
          @mouseenter="selected = i"
          v-for="(option, i) in users"
          :key="i"
          style="height: 3.5rem"
        >
          <div class="d-flex justify-content-between position-relative">
            <div class="h-100">
              <mega-image
                v-if="option.avatar"
                class="rounded-full navbar-avatar mr-3 overflow-hidden"
                style="justify-self: start; position: absolute"
                small
                saveAsImg
                :src="option.avatar"
              />
            </div>
            <div class="text-overflow mr-auto ml-auto">
              {{ option.name }}
            </div>
          </div>
        </span>
      </nav>
      <nav class="nav flex-column border" v-else>
        <span class="btn h-100 py-1 border-bottom option"> Не найдено </span>
      </nav>
    </perfect-scrollbar>
    <span v-if="help !== undefined" class="form-help">{{ help }}</span>
  </div>
</template>

<script>
import { Picker } from "emoji-mart-vue";
import IconBase from "../../components/icons/IconBase";
import Smile from "../../components/icons/set/Smile";

export default {
  data() {
    return {
      showEmojiPicker: false,
      showSearchPanel: false,
      users: [],
      selected: -1
    };
  },
  props: {
    placeholder: [String, Number],
    label: [String, Number],
    help: {
      type: [String, Number],
      default: undefined
    },
    rows: [String, Number],
    max: Number,
    icon: String,
    disableNewline: Boolean,
    value: [String, Number],
    valid: {
      type: Boolean,
      default: true
    },
    warning: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    document.addEventListener("keydown", this.keyDownHandler(event));
    document.addEventListener("keyup", this.keyUpHandler(event));
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.keyUpHandler);
    document.removeEventListener("keydown", this.keyDownHandler);
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    style() {
      return {
        "form-success": this.success,
        "form-warning": this.warning,
        "form-danger": !this.valid,
        "form-primary": this.valid
      };
    },
    lgt() {
      return this._value ? this._value.length : 0;
    }
  },
  methods: {
    keyDownHandler(e) {
      if (this.showSearchPanel) {
        if (e.keyCode === 13 || e.key === "ArrowDown" || e.key === "ArrowUp")
          e.preventDefault();
      }
    },
    keyUpHandler(e) {
      if (this.showSearchPanel) {
        e = e || window.event;

        if (e.keyCode === 13) {
          e.preventDefault();
          this.setUser(this.users[this.selected].username);
        }

        if (e.keyCode === 38 && this.selected > 0) {
          e.preventDefault();
          this.selected--;
        }

        if (e.keyCode === 40 && this.selected < this.users.length - 1) {
          this.selected++;
          e.preventDefault();
        }
      }
    },
    onKeyDown(e) {
      if (this.disableNewline && e.keyCode === 13) e.preventDefault();
      setTimeout(() => {
        let word_arr = this.value.split(" ");
        let last_word = word_arr[word_arr.length - 1];
        if (last_word.startsWith("@")) {
          this.showSearchPanel = true;
          this.search(last_word.slice(1));
        } else {
          this.showSearchPanel = false;
        }
      }, 0);

      this.$emit("keydown", e);
    },
    setUser(name) {
      var lastIndex = this.value.lastIndexOf("@");
      this._value = this.value.substring(0, lastIndex);
      setTimeout(() => {
        this._value = this.value + "@" + name + " ";
        this.showSearchPanel = false;
        this.selected = -1;
      }, 0);
    },
    search(search) {
      let params = {};

      if (search) params["search"] = search;

      this.$api.v1.get("/userList", { params }).then(response => {
        this.users = [];

        response.data.users.map(user => {
          this.users.push({
            value: user.idt_user,
            name: user.name + (user.username ? " (" + user.username + ")" : ""),
            avatar: user.avatar ? user.avatar : "",
            username: user.username
          });
        });
      });
    },
    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    addEmoji(emoji) {
      const textarea = this.$refs.textarea;
      const cursorPosition = textarea.selectionEnd;
      const start = this.value.substring(0, textarea.selectionStart);
      const end = this.value.substring(textarea.selectionStart);
      const text = start + emoji.native + end;
      this.$emit("input", text);
      textarea.focus();
      this.$nextTick(() => {
        textarea.selectionEnd = cursorPosition + emoji.native.length;
      });
    }
  },
  components: {
    Picker,
    IconBase,
    Smile
  }
};
</script>

<style lang="sass" scoped>
.input-wrap
  position: relative
  z-index: 100
  .emoji-trigger
    position: absolute
    right: -1.9rem
    top: 0.1rem
    cursor: pointer
  .emoji-panel
    position: absolute
    top: 2rem
    right: 0rem
    z-index: 102
    transition: display 0.3s
.option
  &:last-child
    border-bottom: none
.selected
  color: #ffffff
  background: #5995ed
.scroll-wrap
  max-height: 20rem
</style>
