var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("div", { staticClass: "d-flex" }, [
        _vm.label
          ? _c("label", { staticClass: "form-label" }, [
              _vm._v(_vm._s(_vm.label))
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.max
          ? _c(
              "span",
              {
                staticClass: "ml-auto badge badge-sm rounded-bottom-0",
                class: {
                  "bg-primary": _vm.lgt < (_vm.max / 3) * 2,
                  "bg-warning": _vm.lgt >= (_vm.max / 3) * 2
                }
              },
              [_vm._v(_vm._s(_vm.lgt) + "/" + _vm._s(_vm.max))]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-wrap" },
        [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm._value,
                expression: "_value"
              }
            ],
            ref: "textarea",
            staticClass: "form-input",
            class: _vm.style,
            style: { height: "130px" },
            attrs: {
              "aria-label": "textarea",
              rows: _vm.rows || 10,
              placeholder: _vm.placeholder,
              maxlength: _vm.max
            },
            domProps: { value: _vm._value },
            on: {
              click: function($event) {
                return _vm.$emit("click", $event)
              },
              keyup: function($event) {
                return _vm.$emit("keyup", $event)
              },
              input: [
                function($event) {
                  if ($event.target.composing) return
                  _vm._value = $event.target.value
                },
                _vm.onKeyDown
              ],
              keypress: function($event) {
                return _vm.$emit("keypress", $event)
              },
              change: function($event) {
                return _vm.$emit("change", $event)
              },
              blur: function($event) {
                return _vm.$emit("blur", $event)
              },
              focus: function($event) {
                return _vm.$emit("focus", $event)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "emoji-trigger",
              class: { triggered: _vm.showEmojiPicker },
              on: {
                mousedown: function($event) {
                  $event.preventDefault()
                  return _vm.toggleEmojiPicker.apply(null, arguments)
                },
                blur: _vm.toggleEmojiPicker
              }
            },
            [
              _c(
                "IconBase",
                { attrs: { width: 40, viewbox: "0 0 24 24" } },
                [_c("Smile")],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("picker", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showEmojiPicker,
                expression: "showEmojiPicker"
              }
            ],
            staticClass: "emoji-panel",
            attrs: { title: "Pick your emoji...", emoji: "point_up" },
            on: { select: _vm.addEmoji }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.showSearchPanel
        ? _c(
            "perfect-scrollbar",
            { staticClass: "scroll-wrap d-flex flex-column" },
            [
              _vm.users
                ? _c(
                    "nav",
                    { staticClass: "nav flex-column border" },
                    _vm._l(_vm.users, function(option, i) {
                      return _c(
                        "span",
                        {
                          key: i,
                          staticClass: "btn h-100 py-1 border-bottom option",
                          class: { selected: _vm.selected === i },
                          staticStyle: { height: "3.5rem" },
                          on: {
                            click: function($event) {
                              return _vm.setUser(option.username)
                            },
                            mouseenter: function($event) {
                              _vm.selected = i
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between position-relative"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "h-100" },
                                [
                                  option.avatar
                                    ? _c("mega-image", {
                                        staticClass:
                                          "rounded-full navbar-avatar mr-3 overflow-hidden",
                                        staticStyle: {
                                          "justify-self": "start",
                                          position: "absolute"
                                        },
                                        attrs: {
                                          small: "",
                                          saveAsImg: "",
                                          src: option.avatar
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "text-overflow mr-auto ml-auto"
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(option.name) +
                                      "\n          "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                : _c("nav", { staticClass: "nav flex-column border" }, [
                    _c(
                      "span",
                      { staticClass: "btn h-100 py-1 border-bottom option" },
                      [_vm._v(" Не найдено ")]
                    )
                  ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.help !== undefined
        ? _c("span", { staticClass: "form-help" }, [_vm._v(_vm._s(_vm.help))])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }